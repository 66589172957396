import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _ruleError2 from "./ruleError";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateAttribute;

var _ruleError = _interopRequireDefault(_ruleError2);

const WHITELIST = ["mj-class", "css-class"];

function validateAttribute(element, {
  components
}) {
  const {
    attributes,
    tagName
  } = element;
  const Component = components[tagName];

  if (!Component) {
    return null;
  }

  const availableAttributes = [...Object.keys(Component.allowedAttributes || {}), ...WHITELIST];
  const unknownAttributes = Object.keys(attributes || {}).filter(attribute => !availableAttributes.includes(attribute));

  if (unknownAttributes.length === 0) {
    return null;
  }

  const {
    attribute,
    illegal
  } = {
    attribute: unknownAttributes.length > 1 ? "Attributes" : "Attribute",
    illegal: unknownAttributes.length > 1 ? "are illegal" : "is illegal"
  };
  return (0, _ruleError.default)(`${attribute} ${unknownAttributes.join(", ")} ${illegal}`, element);
}

exports = exports.default;
export default exports;