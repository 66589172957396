import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _ruleError2 from "./rules/ruleError";
import _MJMLRulesCollection2 from "./MJMLRulesCollection";
import _dependencies2 from "./dependencies";
var exports = {};
var _interopRequireWildcard = _interopRequireWildcard2.default;
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MJMLValidator;
Object.defineProperty(exports, "rulesCollection", {
  enumerable: true,
  get: function () {
    return _MJMLRulesCollection.default;
  }
});
Object.defineProperty(exports, "registerRule", {
  enumerable: true,
  get: function () {
    return _MJMLRulesCollection.registerRule;
  }
});
Object.defineProperty(exports, "dependencies", {
  enumerable: true,
  get: function () {
    return _dependencies.default;
  }
});
Object.defineProperty(exports, "registerDependencies", {
  enumerable: true,
  get: function () {
    return _dependencies.registerDependencies;
  }
});
Object.defineProperty(exports, "assignDependencies", {
  enumerable: true,
  get: function () {
    return _dependencies.assignDependencies;
  }
});
exports.formatValidationError = void 0;

var _ruleError = _interopRequireDefault(_ruleError2);

var _MJMLRulesCollection = _interopRequireWildcard(_MJMLRulesCollection2);

var _dependencies = _interopRequireWildcard(_dependencies2);

const SKIP_ELEMENTS = ["mjml"];
const formatValidationError = _ruleError.default;
exports.formatValidationError = formatValidationError;

function MJMLValidator(element, options = {}) {
  const {
    children,
    tagName
  } = element;
  const errors = [];
  const skipElements = options.skipElements || SKIP_ELEMENTS;

  if (options.dependencies == null) {
    console.warn("\"dependencies\" option should be provided to mjml validator");
  }

  if (!skipElements.includes(tagName)) {
    for (const rule of Object.values(_MJMLRulesCollection.default)) {
      const ruleError = rule(element, {
        dependencies: _dependencies.default,
        skipElements,
        ...options
      });

      if (Array.isArray(ruleError)) {
        errors.push(...ruleError);
      } else if (ruleError) {
        errors.push(ruleError);
      }
    }
  }

  if (children && children.length > 0) {
    for (const child of children) {
      errors.push(...MJMLValidator(child, options));
    }
  }

  return errors;
}

export default exports;
export const __esModule = exports.__esModule,
      rulesCollection = exports.rulesCollection,
      registerRule = exports.registerRule,
      dependencies = exports.dependencies,
      registerDependencies = exports.registerDependencies,
      assignDependencies = exports.assignDependencies;
const _formatValidationError = exports.formatValidationError;
export { _formatValidationError as formatValidationError };