import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _validAttributes2 from "./rules/validAttributes";
import _validChildren2 from "./rules/validChildren";
import _validTag2 from "./rules/validTag";
import _validTypes2 from "./rules/validTypes";
import _errorAttr2 from "./rules/errorAttr";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.registerRule = registerRule;
exports.default = void 0;

var _validAttributes = _interopRequireDefault(_validAttributes2);

var _validChildren = _interopRequireDefault(_validChildren2);

var _validTag = _interopRequireDefault(_validTag2);

var _validTypes = _interopRequireDefault(_validTypes2);

var _errorAttr = _interopRequireDefault(_errorAttr2);

const MJMLRulesCollection = {
  validAttributes: _validAttributes.default,
  validChildren: _validChildren.default,
  validTag: _validTag.default,
  validTypes: _validTypes.default,
  errorAttr: _errorAttr.default
};

function registerRule(rule, name) {
  if (typeof rule !== "function") {
    return console.error("Your rule must be a function");
  }

  if (name) {
    MJMLRulesCollection[name] = rule;
  } else {
    MJMLRulesCollection[rule.name] = rule;
  }

  return true;
}

var _default = MJMLRulesCollection;
exports.default = _default;
export default exports;