import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _ruleError2 from "./ruleError";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateType;

var _ruleError = _interopRequireDefault(_ruleError2);

function validateType(element, {
  components,
  initializeType
}) {
  const {
    attributes,
    tagName
  } = element;
  const Component = components[tagName];

  if (!Component) {
    return null;
  }

  const errors = [];

  for (const [attr, value] of Object.entries(attributes || {})) {
    const attrType = Component.allowedAttributes && Component.allowedAttributes[attr];

    if (attrType) {
      const TypeChecker = initializeType(attrType);
      const result = new TypeChecker(value);

      if (result.isValid() === false) {
        errors.push((0, _ruleError.default)(`Attribute ${attr} ${result.getErrorMessage()}`, element));
      }
    }
  }

  return errors;
}

exports = exports.default;
export default exports;