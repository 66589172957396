import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _ruleError2 from "./ruleError";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = errorAttr;

var _ruleError = _interopRequireDefault(_ruleError2);

function errorAttr(element) {
  const {
    errors
  } = element;
  if (!errors) return null;
  return errors.map(error => {
    switch (error.type) {
      case "include":
        {
          const {
            file,
            partialPath
          } = error.params;
          return (0, _ruleError.default)(`mj-include fails to read file : ${file} at ${partialPath}`, element);
        }

      default:
        return null;
    }
  });
}

exports = exports.default;
export default exports;