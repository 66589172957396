import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import _ruleError2 from "./ruleError";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2.default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateTag;

var _ruleError = _interopRequireDefault(_ruleError2); // Tags that have no associated components but are allowed even so


const componentLessTags = ["mj-all", "mj-class", "mj-selector", "mj-html-attribute"];

function validateTag(element, {
  components
}) {
  const {
    tagName
  } = element;
  if (componentLessTags.includes(tagName)) return null;
  const Component = components[tagName];

  if (!Component) {
    return (0, _ruleError.default)(`Element ${tagName} doesn't exist or is not registered`, element);
  }

  return null;
}

exports = exports.default;
export default exports;